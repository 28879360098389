import {ROUTES} from 'js/router/routerConstants';
import {SUPPLEMENTAL_DETAILS_PROP} from 'js/constants';
import type {LanguageCode} from 'js/components/languages/languagesStore';
import {hashHistory} from 'react-router';

/**
 * Returns a path that leads to transcription value in the submission response,
 * something like `_supplementalDetails/your_name/transcript_pl`.
 */
export function getSupplementalTranscriptPath(
  questionName: string,
  languageCode: LanguageCode
) {
  return `${SUPPLEMENTAL_DETAILS_PROP}/${questionName}/transcript_${languageCode}`;
}

/**
 * Returns a path that leads to translation value in the submission response,
 * something like `_supplementalDetails/your_name/translated_pl`.
 */
export function getSupplementalTranslationPath(
  questionName: string,
  languageCode: LanguageCode
) {
  return `${SUPPLEMENTAL_DETAILS_PROP}/${questionName}/translation_${languageCode}`;
}

/**
 * Receives one of paths generated by `getSupplementalTranscriptPath` and
 * `getSupplementalTranslationPath`.
 */
export function getSupplementalPathParts(path: string): {
  sourceRowName: string;
  isTranscript: boolean;
  isTranslation: boolean;
  languageCode: LanguageCode;
} {
  const pathArr = path.split('/');
  const path2Arr = pathArr[2].split('_');
  return {
    sourceRowName: pathArr[1],
    isTranscript: path2Arr[0] === 'transcript',
    isTranslation: path2Arr[0] === 'translation',
    languageCode: path2Arr[1],
  };
}

/** Opens processing view for given response to question in a project. */
export function openProcessing(
  assetUid: string,
  qpath: string,
  submissionEditId: string,
) {
  const route = ROUTES.FORM_PROCESSING
    .replace(':uid', assetUid)
    .replace(':qpath', qpath)
    .replace(':submissionEditId', submissionEditId);
  hashHistory.push(route);
}
